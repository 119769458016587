import '@whispli/loading'
import '@whispli/client/tenant/openapi'
import { getCustomLandingPage, getURL } from '@/client/tenant'
import { LOCALE_CODES } from '@whispli/i18n/constants'
import { getInitialLocale } from '@whispli/i18n/utils/get-initial-locale'
import { PUBLIC_PATH_ENUM } from '@whispli/types'

if (import.meta.env.MODE === 'production' && 'serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    const [
      { registerSW },
    ] = await Promise.all([
      import('@whispli/client/sw'),
    ])

    await registerSW(undefined, undefined, {
      path: `${PUBLIC_PATH_ENUM.PAGES_WEB}worker.js`,
      scope: PUBLIC_PATH_ENUM.PAGES_WEB_CUSTOM,
    })
  })
}

(async () => {
  const {
    slug, url, locale,
  } = getURL()
  const page = await getCustomLandingPage(slug, locale || getInitialLocale(LOCALE_CODES))

  if (page) {
    const iframe = document.createElement('iframe')

    iframe.id = page.slug
    iframe.setAttribute('data-test-id', 'iframe')
    iframe.src = page.url
    iframe.style.height = '100vh'
    iframe.style.width = '100vw'
    iframe.style.border = 'none'
    iframe.allowFullscreen = true

    document.body.innerHTML = ''
    document.body.appendChild(iframe)
  } else {
    if (url.pathname !== `${import.meta.env.VITE_PUBLIC_PATH}404`) {
      url.searchParams.append('slug', slug)
      window.location.replace(url.origin + `${import.meta.env.VITE_PUBLIC_PATH}404?${url.searchParams.toString()}`)
    }
  }

  document.body.classList.remove('loading')
})()

